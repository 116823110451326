<template>
    <div class="contain">
        <div class="contain-table">
            <el-table
                    :data="tableData"
                    :header-cell-style="{ background:'#b14040',color:'#fff'}"
                    border
                    style="width: 100%">
                <el-table-column label="顺序" align="center">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.row.sort}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="姓名" align="center">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="头像" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatarUrl"
                             style="width: 50px;height: 50px;border-radius: 50%"
                             alt="">
                    </template>
                </el-table-column>
                <el-table-column label="简介" show-overflow-tooltip align="center" width="400">
                    <template slot-scope="scope">
                        <span style="color: #b14040">{{scope.row.descContent}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="contain-btn">
            <el-button style="background-color: #bf353b;color: #fff" size="small" @click="startDraw">
                再次开始抽签
            </el-button>
            <el-button style="background-color: #bf353b;color: #fff" size="small" @click="getInEventControl">
                开始比赛
            </el-button>
        </div>
    </div>
</template>
<script>
    import {findByEventId} from "../../api/personnel";
    import {editEventStatus, findById, startDraw} from "../../api/event";

    export default {
        name: "drawControl",
        data() {
            return {
                tableData: [],
                eventInfo: null,
                roleFlag:null
            }
        },
        created() {
            this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
            this.getEventInfo(this.eventInfo.id)
            this.findByEventId(this.eventInfo.id);
        },
        mounted() {
            //1管理 2评委 3主持 4大屏端
            this.roleFlag = sessionStorage.getItem('roleFlag');
        },
        methods: {
            //抽签
            startDraw() {
                this.$confirm('确定再次抽签, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    startDraw(this.eventInfo.id).then(res => {
                        if (res.data.code === 200) {
                            this.tableData = res.data.data
                            this.$message.success('抽签成功')
                        }
                    })
                })
            },
            getInEventControl() {
                this.$confirm('开始比赛, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //改变抽签状态
                    let obj = {
                        eventId: this.eventInfo.id,
                        status: 3
                    }
                    editEventStatus(obj).then(res => {
                        if (res.data.code === 200) {
                            this.$router.push('/eventControl')
                        } else {
                            let data = res.data.data;
                            if (data && data === 2) {
                                this.$router.push('/drawControl')
                            }
                            if (data && data === 1) {
                                this.$router.push('/hostPage')
                            }
                            if (data && data === 4) {
                                this.$router.push('/eventOver')
                            }
                        }
                    })
                })
            },
            //查询比赛信息
            async getEventInfo(id) {
                const {data} = await findById(id);
                if (data.code === 200) {
                    this.eventInfo = data.data
                    if (this.roleFlag === '3') {
                        if (this.eventInfo.startStatus === 1) {
                            this.$router.push('/hostPage')
                        }
                        if (this.eventInfo.startStatus === 2) {
                            this.$router.push('/drawControl')
                        }
                        if (this.eventInfo.startStatus === 3) {
                            this.$router.push('/eventControl')
                        }
                        if (this.eventInfo.startStatus === 4) {
                            this.$router.push('/eventOver')
                        }
                    }
                    if (this.roleFlag === '4') {
                        if (this.eventInfo.startStatus === 1) {
                            this.$router.push('/beforeShow')
                        }
                        if (this.eventInfo.startStatus === 2) {
                            this.$router.push('/drawSort')
                        }
                        if (this.eventInfo.startStatus === 3) {
                            this.$router.push('/eventShow')
                        }
                        if (this.eventInfo.startStatus === 4) {
                            this.$router.push('/showOver')
                        }
                    }

                }
            },
            //查询比赛选手
            async findByEventId(id) {
                const {data} = await findByEventId(id)
                if (data.code === 200) {
                    this.tableData = data.data
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 100%;
        background: url("../../assets/images/hostBanner.png") no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .contain-table {
            width: 60%;
        }

        .contain-btn {
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
